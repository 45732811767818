import { createApi } from '@reduxjs/toolkit/query/react';
import { IReClassificationAssessment } from './types';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';
import configs from '../../app/configs';

export const ReClassificationApi = createApi({
  reducerPath: 'ReClassificationApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      addAssessment: builder.mutation({
        query: (payload: IReClassificationAssessment) => ({
          url: configs.endpoints?.reClassificationAssessments,
          method: 'POST',
          body: payload,
        }),
      }),
    };
  },
});

export const { useAddAssessmentMutation } = ReClassificationApi;
