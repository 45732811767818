import React, {
  Suspense,
  useEffect,
  useState,
} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import PnSpinner from './components/pn-spinner/PnSpinner';
import {
  getTUserAuthDataFromToken,
  localStorageKeys,
} from './utils/auth/auth.util';
import { useFetchTenantUserQuery } from './services/commercialization/users/api';
import Forbidden from './components/auth/Forbidden';
import ServerError from './components/auth/ServerError';
import useAuth from './custom-hooks/useAuth';
import { setTenantUserData } from './features/commercialization/tenant-user-data';
import { useAppDispatch } from './app/hooks';
import { useFetchUserPermissionsQuery } from './features/users/api';
import { setPermissionsData } from './features/permissions/permissionsAlice';

const Layout = React.lazy(() => import('./components/layout/Layout'));

function App() {
  const dispatch = useAppDispatch();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const isAuthenticated = useAuth();

  const {
    data: user,
    isLoading: userLoading,
    isSuccess,
    isError,
    isFetching: userFetching,
  } = useFetchTenantUserQuery(username, { skip: !isAuthenticated || !username });
  const {
    data: userPermissions,
    isLoading: userPermissionsLoading,
    isFetching: userPermissionsFetching,
  } = useFetchUserPermissionsQuery(email, { skip: !isAuthenticated || !email });

  useEffect(() => {
    if (isAuthenticated) {
      const userData = getTUserAuthDataFromToken(localStorageKeys.tenantAccessToken);
      setUsername(userData.username);
      setEmail(userData.email);
    }
    if (user) {
      dispatch(setTenantUserData(user.data));
    }
    if (userPermissions
      && Array.isArray(userPermissions?.data)
      && userPermissions.data.length > 0) {
      dispatch(setPermissionsData({ userPermissions: userPermissions?.data }));
      localStorage.setItem(localStorageKeys.userPermissions, JSON.stringify(userPermissions?.data || []));
    }
  }, [
    isAuthenticated,
    user,
    dispatch,
    userPermissions,
  ]);

  if (userLoading
    || userFetching
    || userPermissionsLoading
    || userPermissionsFetching
  ) return <PnSpinner />;

  if ((isSuccess && !user?.data)
    || (user?.data && user?.data.active !== true)
  ) return (<Forbidden />);

  if (isError && !user?.data) return <ServerError />;

  return (
    <Router>
      <Suspense fallback={<PnSpinner />}>
        <Layout />
      </Suspense>
    </Router>
  );
}

export default App;
