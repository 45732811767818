import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import assetDataReducer from '../features/asset-data';
import substrateInformationReducer, { subStrateInfoSlice } from '../features/RepairDesign/DesignInput/SubstrateInformation';
import { designOutputConsiderationApi } from '../features/RepairDesign/DesignOutput';
import defectInformationReducer, { defectInfoSlice } from '../features/RepairDesign/DesignInput/DefectInformation';
import laminateInformationReducer from '../features/RepairDesign/DesignInput/LaminateInformation';
import designSpecificationReducer, { designSpecSlice } from '../features/RepairDesign/DesignInput/DesignSpecification';
import oddSpecificationReducer, { oddGeometrySlice } from '../features/RepairDesign/DesignInput/OddGeometrySpecification';
import axialExtentOfRepairReducer, { AERSlice } from '../features/RepairDesign/DesignInput/AxialExtentOfRepair';
import externalLoadsInformationReducer, { externalLoadsInformationApi } from '../features/RepairDesign/DesignInput/ExternalLoadsInformation';
import { repairRegistrationApiSlice } from '../features/NewRepairRegistration/slice';
import { rlteAssessmentSlice } from '../features/lifetime-extension/rlte-assessment';
import designRevalidationReducer from '../features/lifetime-extension/design-revalidation/slice';
import userRoleReducer from '../features/user-roles';
import usersReducer from '../features/users';
import lifetimeExtensionModalReducer from '../features/lifetime-extension/modal';
import postApplicationReducer from '../features/RBIC/PostApplication';
import riskAssessmentReducer from '../features/lifetime-extension/risk-assessment';
import riskAssessReClassifyReducer from '../features/re-classification/risk-assessment';
import documentationReducer from '../features/Documentation';
import DesignRevalidationReclassificationReducer from '../features/re-classification/DesignRevalidation';
import { submissionAndApprovalApiSlice } from '../services/SubmissionAndApproval';
import submissionAndApprovalReducer from '../services/SubmissionAndApproval/slice';
import { userApiSlice } from '../features/users/api';
import repairDesignInputReducer from '../features/RepairDesign/DesignInput/index';
import { moduleApiSlice } from '../features/module';
import { dogsApi } from '../services/dogs/dogsApi';
import { rbicApiSlice } from '../services/RBIC';
import rbicResultReducer from '../features/RBIC/Result';
import { impApi } from '../services/RBIC/IMP';
import commonLoaderReducer from '../features/loader';
import { ReClassificationApi } from '../services/re-classification';
import { cofApi } from '../services/cof';
import lifetimeSearchReducer from '../features/lifetime-search';
import { roleApiSlice } from '../services/roles';
import lifeTimeExtensionUniqueIdReducer from '../features/lifetime-extension';
import reClassificationUniqueIdReducer from '../features/re-classification';
import rbicHistoryReducer from '../features/RBIC/history';
import addedAssetDataReducer from '../features/NewRepairRegistration';
import { compositeRepairApiSlice as compositeRepairApi } from '../services/composite-repair-regstration';
import compositeRepairRegistrationReducer from '../features/composite-repair-data';
import { dashboardApiSlice } from '../services/Dashboard';
import substrateInformationResponseReducer from '../features/RepairDesign/DesignInput/Responses';
import repairHistoryReducer from '../features/repair/history';
import repairDesignHistoryReducer from '../features/RepairDesign/history';
import { repairApiSlice } from '../services/repair';
import { repairDesignAPI } from '../services/repair-design';
import { appConfigApiSlice } from '../services/commercialization/app-config';
import permissionsAlice from '../features/permissions/permissionsAlice';
import { addDocumentSlice } from '../services/lifetime-extension';
import { tenantApi } from '../services/commercialization/tenant';
import { tenantLoginApi } from '../services/commercialization/login';
import { tenantUserApi } from '../services/commercialization/users/api';
import tenantData from '../features/commercialization/tenants';
import tenantUserData from '../features/commercialization/tenant-user-data';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    assetData: assetDataReducer,
    substrateInformationResponse: substrateInformationResponseReducer,
    addedAssetData: addedAssetDataReducer,
    lifeTimeExtensionUniqueId: lifeTimeExtensionUniqueIdReducer,
    reClassificationUniqueId: reClassificationUniqueIdReducer,
    [dogsApi.reducerPath]: dogsApi.reducer,
    substrateInformation: substrateInformationReducer,
    defectInformation: defectInformationReducer,
    [repairRegistrationApiSlice.reducerPath]: repairRegistrationApiSlice.reducer,
    [rlteAssessmentSlice.reducerPath]: rlteAssessmentSlice.reducer,
    laminateInformation: laminateInformationReducer,
    designSpecification: designSpecificationReducer,
    oddSpecification: oddSpecificationReducer,
    axialExtentOfRepair: axialExtentOfRepairReducer,
    externalLoadsInformation: externalLoadsInformationReducer,
    userRoles: userRoleReducer,
    updateUser: usersReducer,
    designRevalidation: designRevalidationReducer,
    lifetimeExtensionModal: lifetimeExtensionModalReducer,
    postApplication: postApplicationReducer,
    riskAssessment: riskAssessmentReducer,
    riskAssessReClassify: riskAssessReClassifyReducer,
    documentation: documentationReducer,
    designRevalidationReclassification: DesignRevalidationReclassificationReducer,
    [roleApiSlice.reducerPath]: roleApiSlice.reducer,
    [userApiSlice.reducerPath]: userApiSlice.reducer,
    [submissionAndApprovalApiSlice.reducerPath]: submissionAndApprovalApiSlice.reducer,
    [moduleApiSlice.reducerPath]: moduleApiSlice.reducer,
    [rbicApiSlice.reducerPath]: rbicApiSlice.reducer,
    [impApi.reducerPath]: impApi.reducer,
    repairDesignInput: repairDesignInputReducer,
    submissionAndApproval: submissionAndApprovalReducer,
    compositeRepairData: compositeRepairRegistrationReducer,
    rbicResult: rbicResultReducer,
    commonLoader: commonLoaderReducer,
    [ReClassificationApi.reducerPath]: ReClassificationApi.reducer,
    [cofApi.reducerPath]: cofApi.reducer,
    lifetimeSearch: lifetimeSearchReducer,
    rbicHistory: rbicHistoryReducer,
    repairHistory: repairHistoryReducer,
    repairDesignHistory: repairDesignHistoryReducer,
    [compositeRepairApi.reducerPath]: compositeRepairApi.reducer,
    [dashboardApiSlice.reducerPath]: dashboardApiSlice.reducer,
    [designSpecSlice.reducerPath]: designSpecSlice.reducer,
    [subStrateInfoSlice.reducerPath]: subStrateInfoSlice.reducer,
    [designOutputConsiderationApi.reducerPath]: designOutputConsiderationApi.reducer,
    [defectInfoSlice.reducerPath]: defectInfoSlice.reducer,
    [oddGeometrySlice.reducerPath]: oddGeometrySlice.reducer,
    [AERSlice.reducerPath]: AERSlice.reducer,
    [repairApiSlice.reducerPath]: repairApiSlice.reducer,
    [externalLoadsInformationApi.reducerPath]: externalLoadsInformationApi.reducer,
    permissions: permissionsAlice,
    [addDocumentSlice.reducerPath]: addDocumentSlice.reducer,
    [appConfigApiSlice.reducerPath]: appConfigApiSlice.reducer,
    [repairDesignAPI.reducerPath]: repairDesignAPI.reducer,
    [tenantApi.reducerPath]: tenantApi.reducer,
    [tenantLoginApi.reducerPath]: tenantLoginApi.reducer,
    [tenantUserApi.reducerPath]: tenantUserApi.reducer,
    tenantData,
    tenantUserData,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
    dogsApi.middleware,
    repairRegistrationApiSlice.middleware,
    subStrateInfoSlice.middleware,
    designOutputConsiderationApi.middleware,
    defectInfoSlice.middleware,
    oddGeometrySlice.middleware,
    AERSlice.middleware,
    externalLoadsInformationApi.middleware,
    roleApiSlice.middleware,
    rlteAssessmentSlice.middleware,
    userApiSlice.middleware,
    submissionAndApprovalApiSlice.middleware,
    moduleApiSlice.middleware,
    rbicApiSlice.middleware,
    impApi.middleware,
    ReClassificationApi.middleware,
    cofApi.middleware,
    compositeRepairApi.middleware,
    dashboardApiSlice.middleware,
    designSpecSlice.middleware,
    repairApiSlice.middleware,
    addDocumentSlice.middleware,
    appConfigApiSlice.middleware,
    repairDesignAPI.middleware,
    tenantApi.middleware,
    tenantLoginApi.middleware,
    tenantUserApi.middleware,
  ]),
  devTools: process.env.NODE_ENV !== 'production',
});

// hooks with generic types
export type StoreDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
