import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  Documentation,
  IAPICompositeWrap,
  IAPIRepairDesign,
  IRepairById,
  Prims,
} from '../../../services/repair/repair.type';
import {
  IRepairHistoryState,
  RepairHistoryOpertationType,
} from './types';

const initialState: IRepairHistoryState = {
  operation: 'default',
  selectedRepair: null,
};

const repairHistorySlice = createSlice({
  name: 'repairHistory',
  initialState,
  reducers: {
    setRepairOperation: (state, action: PayloadAction<RepairHistoryOpertationType>) => {
      state.operation = action.payload;
    },
    setRepair: (state, action: PayloadAction<IRepairById>) => {
      state.selectedRepair = action.payload;
    },
    setRepairDesign: (state, action: PayloadAction<IAPIRepairDesign>) => {
      if (!state.selectedRepair) return;
      state.selectedRepair = {
        ...state.selectedRepair,
        ...action.payload,
      };
    },
    setPrim: (state, action: PayloadAction<Prims>) => {
      if (!state.selectedRepair) return;
      state.selectedRepair = {
        ...state.selectedRepair,
        prims: action.payload,
      };
    },
    setComposite: (state, action: PayloadAction<IAPICompositeWrap>) => {
      if (!state.selectedRepair) return;
      state.selectedRepair = {
        ...state.selectedRepair,
        compositeWrap: action.payload,
      };
    },
    setDocumentation: (state, action: PayloadAction<Documentation>) => {
      if (!state.selectedRepair) return;
      state.selectedRepair = {
        ...state.selectedRepair,
        documentation: action.payload,
      };
    },
  },
});

export const {
  setRepairOperation,
  setRepair,
  setRepairDesign,
  setPrim,
  setComposite,
  setDocumentation,
} = repairHistorySlice.actions;

export default repairHistorySlice.reducer;
