import { createApi } from '@reduxjs/toolkit/dist/query/react';
import configs from '../../app/configs';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';
import {
  IAPIApplicationMethodsResponse,
  IAPIDefectResponse,
  IAPIDefectTypeResponse,
  IAPIRepairByIDResponse,
  IAPIRepairClassResponse,
  IAPIRepairDropdownResponse,
  IAPIRepairSearchQueryParams,
  IAPIRepairSearchResponse,
  IAPIRepairTypeResponse,
  IAPISeverityResponse,
  IAPISurfaceConditionResponse,
  IAPISurfacePreparationResponse,
} from './repair.type';

export const repairApiSlice = createApi({
  reducerPath: 'repairApi',
  baseQuery: rtkFetchWrapper(),
  tagTypes: ['repair'],
  endpoints(build) {
    return {
      getDropdownData: build.query<IAPIRepairDropdownResponse, void>({ query: () => ({ url: configs?.endpoints?.repairDropdown }) }),
      getAllRepairs: build.query<IAPIRepairSearchResponse, IAPIRepairSearchQueryParams | void>({
        query(queryParams) {
          const options: any = { url: configs?.endpoints?.repairs };

          if (queryParams) {
            options.params = queryParams;
          }

          return options;
        },
        providesTags: ['repair'],
      }),
      getRepairById: build.query<IAPIRepairByIDResponse, string>({
        query(repairId: string) {
          return { url: `${configs?.endpoints?.repairs}/${repairId}` };
        },
      }),
      getRepairTypes: build.query<IAPIRepairTypeResponse, void>({ query: () => ({ url: configs?.endpoints?.repairTypes }) }),
      getDefectTypes: build.query<IAPIDefectTypeResponse, void>({ query: () => ({ url: configs?.endpoints?.defectTypes }) }),
      getApplicationMethods: build.query<IAPIApplicationMethodsResponse, void>({ query: () => ({ url: configs?.endpoints?.applicationMethods }) }),
      getRepairClasses: build.query<IAPIRepairClassResponse, void>({ query: () => ({ url: configs?.endpoints?.repairClasses }) }),
      getSurfaceConditions: build.query<IAPISurfaceConditionResponse, void>({ query: () => ({ url: configs?.endpoints?.surfaceConditions }) }),
      getSurfacePreparations: build.query<IAPISurfacePreparationResponse, void>({ query: () => ({ url: configs?.endpoints?.surfacePreparations }) }),
      getPresenceOfDefects: build.query<IAPIDefectResponse, void>({ query: () => ({ url: configs?.endpoints?.presenceDefects }) }),
      getSeverityOfDefects: build.query<IAPISeverityResponse, void>({ query: () => ({ url: configs?.endpoints?.severityDefects }) }),
      updateRepair: build.mutation({
        query: (payload) => ({
          url: configs?.endpoints?.repairs,
          method: 'PATCH',
          body: payload,
        }),
      }),
    };
  },
});

export const {
  useGetAllRepairsQuery,
  useGetDropdownDataQuery,
  useGetRepairByIdQuery,
  useGetRepairTypesQuery,
  useGetDefectTypesQuery,
  useGetApplicationMethodsQuery,
  useGetRepairClassesQuery,
  useGetSurfaceConditionsQuery,
  useGetSurfacePreparationsQuery,
  useGetPresenceOfDefectsQuery,
  useGetSeverityOfDefectsQuery,
  useUpdateRepairMutation,
} = repairApiSlice;
