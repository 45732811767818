// DUCKS pattern
import {
  PayloadAction,
  createSlice,
} from '@reduxjs/toolkit';
import {
  TENANTS_FORM_INPUTS,
  TenantInterface,
} from '../../../services/commercialization/tenant/types';

const initialState: TenantInterface = {
  [TENANTS_FORM_INPUTS.TENANT_NAME]: '',
  [TENANTS_FORM_INPUTS.TENANT_ACTIVE]: '',
  [TENANTS_FORM_INPUTS.TENANT_ID]: '',
};

const tenantDataSlice = createSlice({
  name: 'tenantData', // slice name
  initialState, // initial state
  reducers: { // reducers
    setTenantValue(state: TenantInterface, action: PayloadAction<TenantInterface>) {
      state[TENANTS_FORM_INPUTS.TENANT_NAME] = action.payload[TENANTS_FORM_INPUTS.TENANT_NAME];
      state[TENANTS_FORM_INPUTS.TENANT_ACTIVE] = action.payload[TENANTS_FORM_INPUTS.TENANT_ACTIVE];
      state[TENANTS_FORM_INPUTS.TENANT_ID] = action.payload[TENANTS_FORM_INPUTS.TENANT_ID];
    },
  },
});

export const { setTenantValue } = tenantDataSlice.actions;
export default tenantDataSlice.reducer;
