import jwt_decode from 'jwt-decode';
// token for SSO and tenantAccessToken & tenantRefreshToken for custom authentication
export const localStorageKeys = {
  ssoToken: 'token',
  tenantAccessToken: 'tenantAccessToken',
  tenantRefreshToken: 'tenantRefreshToken',
  userData: 'userData',
  userPermissions: 'tenantUserPermissions',
};

export const setAuthToken = (token: string, key: string) => {
  if (typeof Storage === 'undefined') {
    throw new Error('No valid storage type found');
  }
  localStorage.setItem(key, token);
};

export const getAuthToken = (key: string) => localStorage.getItem(key);

export function removeAuthToken(key: string) {
  localStorage.removeItem(key);
}

export const hasAuthToken = (key: string) => {
  const token = localStorage.getItem(key);
  if (token) {
    return true;
  }
  return false;
};

export const getLocalStorageData = (key: string) => {
  if (typeof Storage === 'undefined') return null;
  const data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  }
  return null;
};

export const setLocalStorageData = (key: string, value: any) => {
  if (typeof Storage === 'undefined') {
    throw new Error('No valid storage type found');
  }
  localStorage.setItem(key, JSON.stringify(value));
};

export function getTUserAuthDataFromToken(key = localStorageKeys.tenantAccessToken) {
  const token = localStorage.getItem(key);

  // Decode the JWT
  const decoded: {
    exp: number;
    iat: number;
    iss: string;
    tenantId: string;
    username: string;
    email: string;
  } = jwt_decode(token as string);

  return decoded;
}
