import { createApi } from '@reduxjs/toolkit/query/react';
import rtkFetchWrapper from '../../../utils/rtkFetchWrapper';
import configs from '../../../app/configs';
import { Login, LoginResponse } from './types';

export const tenantLoginApi = createApi({
  reducerPath: 'tenantLoginApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      postLoginData: builder.mutation<LoginResponse, Login>({
        query: (payload) => ({
          url: `${configs?.endpoints?.tenantLogin}`,
          method: 'POST',
          body: payload,
        }),
      }),
    };
  },
});

export const { usePostLoginDataMutation } = tenantLoginApi;
