import { createApi } from '@reduxjs/toolkit/query/react';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';
import configs from '../../app/configs';

export const addDocumentSlice = createApi({
  reducerPath: 'addDocApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      addDoc: builder.mutation({
        query: (payload: FormData) => ({
          url: configs.endpoints?.addDoc,
          method: 'POST',
          body: payload,
        }),
      }),
    };
  },
});

export const { useAddDocMutation } = addDocumentSlice;
