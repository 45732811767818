// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TenantUser } from '../../../services/commercialization/users/types';

const initialState = {
  tenantUser: {
    id: '',
    email: '',
    username: '',
    name: '',
    active: false,
    createdBy: '',
    modifiedBy: '',
    createdAt: '',
    updatedAt: '',
    tenantId: '',
  },
};

const tenantUserDataSlice = createSlice({
  name: 'tenantUserData', // slice name
  initialState, // initial state
  reducers: { // reducers
    setTenantUserData(state, action: PayloadAction<TenantUser>) {
      state.tenantUser = {
        ...action.payload,
        modifiedBy: action.payload.modifiedBy !== null ? action.payload.modifiedBy : '',
      };
    },
  },
});

export const { setTenantUserData } = tenantUserDataSlice.actions;
export default tenantUserDataSlice.reducer;
