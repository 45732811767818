import { Button, Result } from 'antd';
import logout from '../../utils/auth/commercialization/handleLogout';
import '../../styles/antdLibStyles.less';

function ServerError() {
  return (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, something went wrong!"
      extra={<Button type="primary" onClick={logout}>Logout</Button>}
    />
  );
}

export default ServerError;
