import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL } from '../app/configs';
import { getAuthToken, localStorageKeys } from './auth/auth.util';

const rtkFetchWrapper = () => fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: async (headers) => {
    const token = getAuthToken(localStorageKeys.tenantAccessToken);
    headers.set('Authorization', `Bearer ${token}`);
    return headers;
  },
});

export const rtkFetchWrapperAssetDataReg = () => fetchBaseQuery({
  baseUrl: 'https://devapi.petronas.com',
  prepareHeaders: async (headers) => {
    const token = getAuthToken(localStorageKeys.tenantAccessToken);
    headers.set('Authorization', `Bearer ${token}`);
    return headers;
  },
});

export default rtkFetchWrapper;
