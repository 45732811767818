import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IRepairDesignHistoryState } from './type';
import { RepairHistoryOpertationType } from '../../repair/history/types';
import { IAPIRepairDesignByID } from '../../../services/repair-design/types';

const initialState: IRepairDesignHistoryState = {
  operation: 'default',
  selectedRepairDesign: null,
};

const repairDesignHistorySlice = createSlice({
  name: 'repairDesignHistory',
  initialState,
  reducers: {
    setRepairDesignOperation: (state, action: PayloadAction<RepairHistoryOpertationType>) => {
      state.operation = action.payload;
    },
    setRepairDesign: (state, action: PayloadAction<IAPIRepairDesignByID>) => {
      state.selectedRepairDesign = action.payload;
    },
  },
});

export const {
  setRepairDesignOperation,
  setRepairDesign,
} = repairDesignHistorySlice.actions;

export default repairDesignHistorySlice.reducer;
