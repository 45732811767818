interface IEnv {
  local: string;
  dev: string;
  stage: string;
  stage_old: string;
  prod: string;
}

const environment = process.env.REACT_APP_NODE_ENV || 'local';

const AAD_CLIENT_ID_FE = process.env.REACT_APP_AAD_CLIENT_ID_FE || '';
const AAD_CLIENT_ID_BE = process.env.REACT_APP_AAD_CLIENT_ID_BE || '';
const AAD_TENANT_ID = process.env.REACT_APP_AAD_TENANT_ID || '';

const configs = {
  api_uri: {
    local: 'http://localhost:7071/api',
    dev: 'https://ptazsg-4corimsap01.azurewebsites.net/api',
    stage: 'https://ptsg-4corimsds-apim.azure-api.net/api',
    stage_old: 'https://ptsg-4corimsdsfunc01.azurewebsites.net/api',
    tenant: 'https://ptsg-4corimsds-apim.azure-api.net/api/tenant/v1',
    prod: 'https://api.corimsds.petronas.com/v1',
  },
  uri: {
    local: 'http://localhost:5000',
    dev: 'https://ptazsg-4corims-ap01.azurewebsites.net',
    stage: 'https://corimsdsqa.petronas.com',
    stage_old: 'https://ptsg-4corimsds-wb01.azurewebsites.net',
    tenant: 'https://corimsdstrial.petronas.com',
    tenant_old: 'https://ptsg-4corimsds-wb02.azurewebsites.net',
    prod: 'https://corimsds.petronas.com',
  },
  aad: {
    clientIdFe: AAD_CLIENT_ID_FE,
    clientIdBe: AAD_CLIENT_ID_BE,
    authority: `https://login.microsoftonline.com/${AAD_TENANT_ID}`,
    apiScope: `api://${AAD_CLIENT_ID_BE}/App.Read`,
  },
  endpoints: {
    applicationMethods: '/application-method-management/application-methods',
    assets: '/asset-management/assets',
    azureFile: '/azure-file/download',
    assetDropDown: '/asset-management/asset-dropdown',
    attributes: '/attribute-management/attributes',
    axialExtentRepairs: '/axial-extent-repair-management/axial-extent-repairs',
    companies: '/company-management/companies',
    compositeWraps: '/composite-wrap-management/composite-wraps',
    extNotPipelineCompaniesList: '/uat/pet/iassure/assetmaintmgmt/pxy/api/v1/api/Corims/GetCompanies',
    compositeRepairData: '/composite-data-management/composite-data',
    compositeRepairDataFilter: '/repair-data-management/filter',
    assetDataFilter: '/asset-data-management/filter',
    repairDesignDataFilter: '/repair-design-management/filter',
    assetDataWithRepairFilter: '/asset-data-management/filter-repair',
    dashboards: '/dashboard-management',
    defectInfo: '/defect-info-management/defect-info',
    defectTypes: '/defect-type-management/defect-types',
    designCodes: '/design-code-management/design-codes',
    designOutput: '/design-output-management/design-output',
    designSpecs: '/design-spec-management/design-specs',
    documentations: '/documentation-management/documentations',
    equipmentTypes: '/equipment-type-management/equipment-types',
    externalLoadInfo: '/external-load-info-management/external-load-info',
    features: '/feature-management/features',
    imp: '/imp-management/imp',
    laminateInfo: '/laminate-info-management/laminate-info',
    materialGrades: '/material-grade-management/material-grades',
    materialSpecs: '/material-spec-management/material-specs',
    materialTypes: '/material-type-management/material-types',
    modules: '/module-management/modules',
    treeData: '/module-management/tree-data',
    oddGeometries: '/odd-geometry-management/odd-geometries',
    presenceDefects: '/presence-defect-management/defects',
    prim: '/prim-management/prim',
    products: '/product-management/products',
    rbic: '/rbic-management/rbic',
    rbicDropdown: '/rbic-management/rbic-dropdown-data',
    repairClasses: '/repair-class-management/repair-classes',
    CoRsToRbicMapping: '/dashboard-management/rbic-mapping',
    repairDesigns: '/repair-design-management/repair-designs',
    repairTypes: '/repair-type-management/repair-types',
    repairs: '/repair-management/repairs',
    repairDropdown: '/repair-management/repair-dropdown-data',
    repairDesignDropdown: 'repair-design-management/dropdown-data',
    addDoc: '/documentation-management/documentations',
    rlteAssessments: '/rlte-assessment-management/rlte-assessments',
    reClassificationAssessments: '/re-classification-assessment-management/re-classification-assessments',
    roles: '/role-management/roles',
    severityDefects: '/severity-defect-management/defects',
    substrateInfo: '/substrate-info-management/substrate-info',
    surfaceConditions: '/surface-condition-management/surface-conditions',
    surfacePreparations: '/surface-preparation-management/surface-preparations',
    users: '/user-management/users',
    userData: '/user-management/user-data',
    cof: '/cof-management/cof',
    ipimsCompaniesList: 'ipims/asset-management/region-info',
    ipimsAccessToken: 'ipims/ex-app-token/access-token',
    ipimsCombinedData: 'ipims/asset-management/combined-info',
    ipimsPipelineList: 'ipims/asset-management/pipline-list',
    iassureCompanyList: 'iassure/asset-management/company-list',
    iassureComponentDetails: 'iassure/asset-management/component-details',
    appConfig: '/app-management/config',
    tenants: '/tenant-management/tenants',
    tenantLogin: '/tenant-user-management/users/login',
    tenantUsers: '/tenant-user-management/users',
    integrityMapping: '/mapping-management/lifetime-extension',
  },
};

export const API_BASE_URL = configs.api_uri[environment as keyof IEnv];
export const FRONTEND_BASE_URL = configs.uri[environment as keyof IEnv];

export default configs;
