import { createApi } from '@reduxjs/toolkit/query/react';
import { RLTEAssessmentInterface } from './types';
import configs from '../../../app/configs';
import rtkFetchWrapper from '../../../utils/rtkFetchWrapper';

export const rlteAssessmentSlice = createApi({
  reducerPath: 'rlteAssessmentApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      addAssessment: builder.mutation({
        query: (payload: RLTEAssessmentInterface) => ({
          url: configs?.endpoints?.rlteAssessments,
          method: 'POST',
          body: payload,
        }),
      }),
    };
  },
});

export const { useAddAssessmentMutation } = rlteAssessmentSlice;
