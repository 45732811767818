import { createApi } from '@reduxjs/toolkit/query/react';
import configs from '../../app/configs';
import { ICaslPermission } from '../../utils/abac.util';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';
import { IFetchUserResponse } from './types';

interface IPermissionResponse {
  success: boolean
  data: ICaslPermission[]
}

interface User {
  name: string
  email: string
  roleId: string
  companyId: number
  status: string
  createdBy?: string
}

interface IFetchUser {
  success: boolean
  data: {
    id: number
    company: {
      companyName: string
      companyId: number
    }
    role: {
      roleName: string;
      roleId: string;
    }
    createdBy: string
    email: string
    name: string
    status: 'active' | 'inactive'
    createdAt: string
  }[]
}

export const userApiSlice = createApi({
  reducerPath: 'usersApi',
  baseQuery: rtkFetchWrapper(),
  tagTypes: ['Users'],
  endpoints(builder) {
    return {
      fetchUsers: builder.query<IFetchUser, number | void>({
        query: () => configs.endpoints.userData,
        providesTags: ['Users'],
      }),
      fetchUser: builder.query<IFetchUserResponse, string>({
        query: (email: string) => `${configs.endpoints.users}/${email}`,
        providesTags: ['Users'],
      }),
      addUser: builder.mutation({
        query: (payload: User) => ({
          url: configs?.endpoints?.users,
          method: 'POST',
          body: payload,
        }),
        // * It doesn't wait for error message to popup if automatic refetch is enabled.
        // invalidatesTags: ['Users'],
      }),
      updateUser: builder.mutation({
        query: (payload) => ({
          url: configs?.endpoints?.users,
          method: 'PATCH',
          body: payload,
        }),
        // * It doesn't wait for any message to popup if automatic refetch is enabled.
        // invalidatesTags: ['Users'],
      }),
      deleteUser: builder.mutation({
        query: (userId: number) => ({
          url: `${configs?.endpoints?.users}/${userId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Users'],
      }),
      fetchUserPermissions: builder.query<IPermissionResponse, string | void>({ query: (email) => ({ url: `${configs?.endpoints?.users}/permissions/${email}` }) }),
    };
  },
});

export const {
  useAddUserMutation,
  useFetchUsersQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useFetchUserPermissionsQuery,
  useFetchUserQuery,
} = userApiSlice;
