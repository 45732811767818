// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  LifetimeSearch, PayloadActionTypeForDocumentationAvailability, PayloadActionTypeForString,
} from './types';

const initialState: LifetimeSearch = {
  id: -1,
  repairId: '',
  designId: '',
  rbicId: '',
  facility: '',
  equipmentType: '',
  equipmentTag: '',
  createdBy: '',
  creationDate: '',
  compositeInstallationDateIMP03: '',
  repairDesignSubmissionDateDD03: '',
  rbicAssessmentDateDD01: '',
  repairLifetimePI01: '',
  recommendedDueDateDD02: '',
  compositeWarrantyDueDateEQ502: '',
  cof: '',
  rbicRiskIMP107: '',
  documentationAvailability: {
    designReport: '',
    installationReport: '',
    postInstallationInspectionReport: '',
    productQualificationReport: '',
    substrateInspectionReport: '',
    compositeRepairDesignDatasheet: '',
    compositeCloseoutReport: '',
    surfacePreparationRecord: '',
    qaqcReport: '',
    imp: '',
  },
};

const lifetimeSearchSlice = createSlice({
  name: 'lifetimeSearch', // slice name
  initialState, // initial state
  reducers: { // reducers
    setLifetimeSearch(state: LifetimeSearch, action: PayloadAction<LifetimeSearch>) {
      state.repairId = action.payload.repairId;
      state.designId = action.payload.designId;
      state.rbicId = action.payload.rbicId;
      state.facility = action.payload.facility;
      state.equipmentType = action.payload.equipmentType;
      state.equipmentTag = action.payload.equipmentTag;
      state.createdBy = action.payload.createdBy;
      state.creationDate = action.payload.creationDate;
      state.compositeInstallationDateIMP03 = action.payload.compositeInstallationDateIMP03;
      state.repairDesignSubmissionDateDD03 = action.payload.repairDesignSubmissionDateDD03;
      state.rbicAssessmentDateDD01 = action.payload.rbicAssessmentDateDD01;
      state.repairLifetimePI01 = action.payload.repairLifetimePI01;
      state.recommendedDueDateDD02 = action.payload.recommendedDueDateDD02;
      state.compositeWarrantyDueDateEQ502 = action.payload.compositeWarrantyDueDateEQ502;
      state.cof = action.payload.cof;
      state.rbicRiskIMP107 = action.payload.rbicRiskIMP107;
      state.documentationAvailability = action.payload.documentationAvailability;
    },
    clearLifetimeSearch(state: LifetimeSearch) {
      state.repairId = '';
      state.designId = '';
      state.rbicId = '';
      state.facility = '';
      state.equipmentType = '';
      state.equipmentTag = '';
      state.createdBy = '';
      state.creationDate = '';
      state.compositeInstallationDateIMP03 = '';
      state.repairDesignSubmissionDateDD03 = '';
      state.rbicAssessmentDateDD01 = '';
      state.repairLifetimePI01 = '';
      state.recommendedDueDateDD02 = '';
      state.compositeWarrantyDueDateEQ502 = '';
      state.cof = '';
      state.rbicRiskIMP107 = '';
      state.documentationAvailability = {
        designReport: '',
        installationReport: '',
        postInstallationInspectionReport: '',
        productQualificationReport: '',
        substrateInspectionReport: '',
        compositeRepairDesignDatasheet: '',
        compositeCloseoutReport: '',
        surfacePreparationRecord: '',
        qaqcReport: '',
        imp: '',
      };
    },
    setLifetimeSearchValue(state: LifetimeSearch, action: PayloadAction<PayloadActionTypeForString>) {
      state[action.payload.key] = action.payload.value;
    },
    setLifetimeSearchDocumentationValue(state: LifetimeSearch, action: PayloadAction<PayloadActionTypeForDocumentationAvailability>) {
      state.documentationAvailability[action.payload.key] = action.payload.value;
    },
  },
});

export const {
  setLifetimeSearch,
  setLifetimeSearchValue,
  setLifetimeSearchDocumentationValue,
  clearLifetimeSearch,
} = lifetimeSearchSlice.actions;
export default lifetimeSearchSlice.reducer;
