// DUCKS pattern
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface RepairDesignInputInterface {
  INP034: any;
  repairDesignId: string;
  callOutputFunc: boolean;
  callFinalFunc: boolean;
}

const initialState: RepairDesignInputInterface = {
  INP034: '',
  repairDesignId: '',
  callOutputFunc: false,
  callFinalFunc: false,
};

const repairDesignInputSlice = createSlice({
  name: 'repairDesignInput', // slice name
  initialState, // initial state
  reducers: { // reducers
    setINP034(state, action: PayloadAction<any>) {
      state.INP034 = action.payload;
    },
    setCallOutput(state, action: PayloadAction<boolean>) {
      state.callOutputFunc = action.payload;
    },
    setCallFinal(state, action: PayloadAction<boolean>) {
      state.callFinalFunc = action.payload;
    },
    setRepairDesignId(state, action: PayloadAction<any>) {
      state.repairDesignId = action.payload;
    },
  },
});

export const {
  setINP034, setCallOutput, setCallFinal, setRepairDesignId,
} = repairDesignInputSlice.actions;
export default repairDesignInputSlice.reducer;
