import { createApi } from '@reduxjs/toolkit/dist/query/react';
import configs from '../../app/configs';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';
import {
  IAPIRbicDashboardResponse,
  IAPIRepairDesignDashboardResponse,
} from './dashboard-api.type';

export const dashboardApiSlice = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(build) {
    return {
      getRbicDashboardData: build.query<IAPIRbicDashboardResponse, number | void>({
        query: (companyId) => ({
          url: `${configs.endpoints?.dashboards}/rbic`,
          params: { companyId },
        }),
      }),
      getRepairDesignDashboardData: build.query<IAPIRepairDesignDashboardResponse, void>({ query: () => ({ url: `${configs.endpoints?.dashboards}/repair-design` }) }),
    };
  },
});

export const {
  useGetRbicDashboardDataQuery,
  useGetRepairDesignDashboardDataQuery,
} = dashboardApiSlice;
