export interface Tenant {
  id: string;
  tenantName: string;
  apiKey?: string;
  connectionString?: string;
  active: boolean;
  environment?: string;
  settings?: string;
  createdAt: string;
  updatedAt: string;
}

export interface TenantResponse {
  success: boolean;
  data: Tenant[];
}

export enum TENANTS_FORM_INPUTS {
  TENANT_NAME = 'tenantName',
  TENANT_ACTIVE = 'active',
  TENANT_ID = 'id',
}

export interface TenantInterface {
  [TENANTS_FORM_INPUTS.TENANT_NAME]: string,
  [TENANTS_FORM_INPUTS.TENANT_ACTIVE]: string,
  [TENANTS_FORM_INPUTS.TENANT_ID]: string,
}

export type TenantKeys = TENANTS_FORM_INPUTS.TENANT_NAME |
  TENANTS_FORM_INPUTS.TENANT_ACTIVE |
  TENANTS_FORM_INPUTS.TENANT_ID;

export interface PayloadActionTypeForString {
  type: TenantInterface;
  payload: string;
}
