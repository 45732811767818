import { createApi } from '@reduxjs/toolkit/query/react';
import configs from '../../../app/configs';
import rtkFetchWrapper from '../../../utils/rtkFetchWrapper';
import {
  IAppConfigResponse,
  // UpdateAppConfigPayload,
} from './types';

export const appConfigApiSlice = createApi({
  reducerPath: 'appConfigApi',
  baseQuery: rtkFetchWrapper(),
  tagTypes: ['App-Config'],
  endpoints(builder) {
    return {
      fetchAppConfig: builder.query<IAppConfigResponse, number>({
        query: (id: number) => ({ url: `${configs?.endpoints?.appConfig}/${id}` }),
        providesTags: ['App-Config'],
      }),
      updateAppConfig: builder.mutation({
        query: (payload: FormData) => ({
          url: configs?.endpoints?.appConfig,
          method: 'PATCH',
          body: payload,
        }),
        invalidatesTags: ['App-Config'],
      }),
    };
  },
});

export const { useFetchAppConfigQuery, useUpdateAppConfigMutation } = appConfigApiSlice;
