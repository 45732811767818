import { createApi } from '@reduxjs/toolkit/query/react';
import rtkFetchWrapper from '../../../utils/rtkFetchWrapper';
import configs from '../../../app/configs';
import {
  FetchTenantUser,
  FetchTenantUsers,
  IPermissionResponse,
  User,
} from './types';

export const tenantUserApi = createApi({
  reducerPath: 'tenantUserApi',
  baseQuery: rtkFetchWrapper(),
  tagTypes: ['TenantUsers'],
  endpoints(builder) {
    return {
      fetchTenantUsers: builder.query<FetchTenantUsers, void>({
        query: () => configs.endpoints.tenantUsers,
        providesTags: ['TenantUsers'],
      }),
      fetchTenantUser: builder.query<FetchTenantUser, string>({
        query: (email: string) => `${configs.endpoints.tenantUsers}/${email}`,
        providesTags: ['TenantUsers'],
      }),
      postTenantUser: builder.mutation({
        query: (payload: User) => ({
          url: configs?.endpoints?.tenantUsers,
          method: 'POST',
          body: payload,
        }),
      }),
      updateTenantUser: builder.mutation({
        query: (payload) => ({
          url: configs?.endpoints?.tenantUsers,
          method: 'PATCH',
          body: payload,
        }),
      }),
      deleteTenantUser: builder.mutation({
        query: (userId: number) => ({
          url: `${configs?.endpoints?.tenantUsers}/${userId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['TenantUsers'],
      }),
      fetchTenantUserPermissions: builder.query<IPermissionResponse, string | void>({
        query: (email) => ({
          url: `${configs?.endpoints?.tenantUsers}/permissions/${email}`,
          method: 'GET',
        }),
      }),
    };
  },
});

export const {
  useFetchTenantUserQuery,
  useFetchTenantUsersQuery,
} = tenantUserApi;
