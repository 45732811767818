import { createApi } from '@reduxjs/toolkit/dist/query/react';
import configs from '../../../app/configs';
import { IIMPPayload } from '../../../components/RBI-C/RBIC.types';
import rtkFetchWrapper from '../../../utils/rtkFetchWrapper';

export const impApi = createApi({
  reducerPath: 'impApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      saveImpData: builder.mutation({
        query: (payload: IIMPPayload) => ({
          url: configs.endpoints.imp,
          method: 'POST',
          body: payload,
        }),
      }),
    };
  },
});

export const { useSaveImpDataMutation } = impApi;
