import { localStorageKeys } from '../auth.util';

const handleLogout = async () => {
  localStorage.removeItem(localStorageKeys.userPermissions);
  localStorage.removeItem(localStorageKeys.tenantAccessToken);
  localStorage.removeItem(localStorageKeys.tenantRefreshToken);
  window.location.href = '/login';
};

export default handleLogout;
