import { createApi } from '@reduxjs/toolkit/query/react';
import { FRONTEND_BASE_URL } from '../../app/configs';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';

export interface SubmissionAndApproval {
  submitterName?: string;
  submitterEmail: string;
  approverName?: string;
  approverEmail: string;
  moduleId: string;
  moduleName: string;
  status: string;
  reviewRemark?: string,
  approvalRemark?: string,
  reviewerName: string;
  reviewerEmail: string;
  submittedAt?: string;
  reviewedAt?: string;
  approvedAt?: string;
  emailTemplate: string
}

export interface SubmissionAndApprovalRequest extends SubmissionAndApproval {
  id?: string;
  FR_BASE_URL?: string;
  moduleURL: string;
}

export const submissionAndApprovalApiSlice = createApi({
  reducerPath: 'submissionAndApprovalApi',
  baseQuery: rtkFetchWrapper(),
  endpoints(builder) {
    return {
      fetchSubmissions: builder.query({ query: () => '/submission-for-review-management/submissions' }),
      fetchReviews: builder.query({ query: () => '/review-for-approval-management/reviews' }),
      fetchReviewsById: builder.query<any, number>({ query: (id: number) => `/submission-approval-management/submission-approvals/${id}` }),
      createSubmissionForReview: builder.mutation({
        query: (payload: SubmissionAndApprovalRequest) => ({
          url: '/submission-for-review-management/submissions',
          method: 'POST',
          body: { ...payload, FR_BASE_URL: FRONTEND_BASE_URL },
        }),
      }),
      createReviewForApproval: builder.mutation({
        query: (payload: SubmissionAndApprovalRequest) => ({
          url: '/review-for-approval-management/reviews',
          method: 'POST',
          body: { ...payload, FR_BASE_URL: FRONTEND_BASE_URL },
        }),
      }),
      rejectSubmissionFromReview: builder.mutation({
        query: (payload: SubmissionAndApprovalRequest) => ({
          url: '/reject-submission-management/reject-from-review',
          method: 'POST',
          body: { ...payload, FR_BASE_URL: FRONTEND_BASE_URL },
        }),
      }),
      approveSubmission: builder.mutation({
        query: (payload: SubmissionAndApprovalRequest) => ({
          url: '/approve-submission-management/approve-submission',
          method: 'POST',
          body: { ...payload, FR_BASE_URL: FRONTEND_BASE_URL },
        }),
      }),
      rejectSubmissionFromApproval: builder.mutation({
        query: (payload: SubmissionAndApprovalRequest) => ({
          url: '/reject-submission-management/reject-from-approval',
          method: 'POST',
          body: { ...payload, FR_BASE_URL: FRONTEND_BASE_URL },
        }),
      }),
    };
  },
});

export const {
  useCreateSubmissionForReviewMutation, useCreateReviewForApprovalMutation, useFetchReviewsQuery, useFetchSubmissionsQuery,
  useFetchReviewsByIdQuery, useApproveSubmissionMutation, useRejectSubmissionFromReviewMutation, useRejectSubmissionFromApprovalMutation,
} = submissionAndApprovalApiSlice;
