import { useEffect, useState } from 'react';
import { localStorageKeys } from '../utils/auth/auth.util';

function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if the user is authenticated here
    const token = localStorage.getItem(localStorageKeys.tenantAccessToken);

    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return isAuthenticated;
}

export default useAuth;
