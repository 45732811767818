import { createApi } from '@reduxjs/toolkit/query/react';
import rtkFetchWrapper from '../../utils/rtkFetchWrapper';
import configs from '../../app/configs';
import {
  IApiRepairDesignByIDResponse,
  IApiRepairDesignListResponse,
  RepairDesignDropDownResponse,
  RepairDesignQueryParams,
} from './types';

export const repairDesignAPI = createApi({
  reducerPath: 'repairDesignAPI',
  baseQuery: rtkFetchWrapper(),
  tagTypes: ['repair-design'],
  endpoints(build) {
    return {
      getDropdownData: build.query<RepairDesignDropDownResponse, void>({ query: () => ({ url: configs?.endpoints?.repairDesignDropdown }) }),
      getList: build.query<IApiRepairDesignListResponse, RepairDesignQueryParams>({
        query(queryParams) {
          const options: any = { url: configs?.endpoints?.repairDesigns };

          if (queryParams) {
            options.params = queryParams;
          }

          return options;
        },
      }),
      getRepairDesignById: build.query<IApiRepairDesignByIDResponse, string>({ query: (rdmID) => ({ url: `${configs?.endpoints?.repairDesigns}/${rdmID}` }) }),
    };
  },
});

export const {
  useGetDropdownDataQuery,
  useGetListQuery,
  useGetRepairDesignByIdQuery,
} = repairDesignAPI;
