export enum USERS_FORM_INPUTS {
  USER_NAME = 'name',
  USER_EMAIL = 'email',
  USER_ROLE = 'roleName',
  USER_ROLE_ID = 'roleId',
  USER_COMPANY = 'company',
  USER_COMPANY_ID = 'companyId',
  USER_STATUS = 'status',
  USER_ID = 'userId',
  USER_CREATED_BY = 'createdBy'
}

export interface UserDataType {
  no: string | number
  key: string
  email: string
  roleName: string;
  roleId: string;
  name: string
  companyName: string
  status: string
  createdBy: string
  createdDate: string
}

export interface UserInterface {
  [USERS_FORM_INPUTS.USER_NAME]: string,
  [USERS_FORM_INPUTS.USER_EMAIL]: string,
  [USERS_FORM_INPUTS.USER_ROLE]: string,
  [USERS_FORM_INPUTS.USER_ROLE_ID]: string,
  [USERS_FORM_INPUTS.USER_COMPANY]: string,
  [USERS_FORM_INPUTS.USER_COMPANY_ID]: number | null,
  [USERS_FORM_INPUTS.USER_STATUS]: string,
  [USERS_FORM_INPUTS.USER_ID]: string,
  [USERS_FORM_INPUTS.USER_CREATED_BY]: string,
}

export type UserKeys = USERS_FORM_INPUTS.USER_NAME |
  USERS_FORM_INPUTS.USER_EMAIL |
  USERS_FORM_INPUTS.USER_ROLE |
  USERS_FORM_INPUTS.USER_ROLE_ID |
  USERS_FORM_INPUTS.USER_COMPANY |
  USERS_FORM_INPUTS.USER_COMPANY_ID |
  USERS_FORM_INPUTS.USER_STATUS |
  USERS_FORM_INPUTS.USER_ID |
  USERS_FORM_INPUTS.USER_CREATED_BY;

export interface PayloadActionTypeForString {
  type: UserInterface;
  payload: string;
}

export interface IFetchUserResponse {
  success: boolean
  data: {
    id: number
    name: string
    email: string
    roleId: string
    status: 'active' | 'inactive'
    createdBy: string
    createdAt: Date
    updatedAt: Date
    company: {
      companyName: string;
      companyId: number;
    }
  } | null
}
