import { createApi } from '@reduxjs/toolkit/query/react';
import rtkFetchWrapper from '../../../utils/rtkFetchWrapper';
import configs from '../../../app/configs';
import { TenantResponse } from './types';
import { ICaslPermission } from '../../../utils/abac.util';

interface IPermissionResponse {
  success: boolean
  data: ICaslPermission[]
}
interface Tenant {
  tenantName: string
  active: string
}

export const tenantApi = createApi({
  reducerPath: 'tenantApi',
  baseQuery: rtkFetchWrapper(),
  // tagTypes: ['Tenants'],
  endpoints(builder) {
    return {
      fetchTenants: builder.query<TenantResponse, void>({
        query: () => configs.endpoints?.tenants,
        // providesTags: ['Tenants'],
      }),
      fetchTenant: builder.query<TenantResponse, string>({
        query: (id: string) => `${configs.endpoints.tenants}/${id}`,
        // providesTags: ['Tenants'],
      }),
      addTenant: builder.mutation({
        query: (payload: Tenant) => ({
          url: configs?.endpoints?.tenants,
          method: 'POST',
          body: payload,
        }),
        // * It doesn't wait for error message to popup if automatic refetch is enabled.
        // invalidatesTags: ['Tenants'],
      }),
      updateTenant: builder.mutation({
        query: (payload) => ({
          url: configs?.endpoints?.tenants,
          method: 'PATCH',
          body: payload,
        }),
        // * It doesn't wait for any message to popup if automatic refetch is enabled.
        // invalidatesTags: ['Tenants'],
      }),
      deleteTenant: builder.mutation({
        query: (id: string) => ({
          url: `${configs?.endpoints?.tenants}/${id}`,
          method: 'DELETE',
        }),
        // invalidatesTags: ['Tenants'],
      }),
      fetchTenantPermissions: builder.query<IPermissionResponse, string | void>({ query: (email) => ({ url: `${configs?.endpoints?.tenants}/permissions/${email}` }) }),
    };
  },
});

export const {
  useAddTenantMutation,
  useFetchTenantsQuery,
  useDeleteTenantMutation,
  useUpdateTenantMutation,
  useFetchTenantPermissionsQuery,
  useFetchTenantQuery,
} = tenantApi;
