// DUCKS pattern
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  repairDesignInputs: {},
  primsInputs: {
    assetDataId: null,
    coinTapTest: '',
    compositeRepairHistory: '',
    compositeRepairHistoryFile: null,
    hardnessTest: '',
    lastInspectionDate: '',
    ndt: '',
    nextInspectionDate: '',
    podId: null,
    podOther: '',
    primReport: null,
    sodId: null,
    sodOther: '',
    visualInspection: '',
  },
  compositeWrapInputs: {},
  documentationInputs: {},
};

const compositeRepairResgistrationSlice = createSlice({
  name: 'compositeRepairResgistration', // slice name
  initialState, // initial state
  reducers: { // reducers
    setRepairDesignInCompositeRepair(state, action) {
      state.repairDesignInputs = action.payload;
    },
    setPrimsInCompositeRepair(state, action) {
      state.primsInputs = action.payload;
    },
    setCompositeWrapInCompositeRepair(state, action) {
      state.compositeWrapInputs = action.payload;
    },
    setDocumentationInCompositeRepair(state, action) {
      state.compositeWrapInputs = action.payload;
    },
  },
});

export const {
  setRepairDesignInCompositeRepair,
  setPrimsInCompositeRepair,
  setCompositeWrapInCompositeRepair,
  setDocumentationInCompositeRepair,
} = compositeRepairResgistrationSlice.actions;
export default compositeRepairResgistrationSlice.reducer;
